import axios from 'axios';

// Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5002';
const REMOTE_API_BASE_URL = 'https://api.crediunisa2.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'local' if needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL
});

// Response interceptor
apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle token expiration (e.g., redirect to login, refresh token, etc.)
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export class CustomerQuoteService {
    // Helper function to get headers with Authorization token
    getAuthHeaders() {
        const accessToken = localStorage.getItem('accessToken');
        return {
          headers: {
              'Authorization': `Bearer ${accessToken}`
          }
        };
    }

    getCustomerQuotes() {
        return apiClient.get(`/customer_quotes`, this.getAuthHeaders());
    }

    getCustomerQuoteById(quoteId) {
        return apiClient.get(`/customer_quotes/${quoteId}`, this.getAuthHeaders());
    }

    addCustomerQuote(customerQuoteData) {
        return apiClient.post(`/customer_quotes`, customerQuoteData, this.getAuthHeaders());
    }

    updateCustomerQuote(quoteId, customerQuoteData) {
        return apiClient.put(`/customer_quotes/${quoteId}`, customerQuoteData, this.getAuthHeaders());
    }

    deleteCustomerQuote(quoteId) {
        return apiClient.delete(`/customer_quotes/${quoteId}`, this.getAuthHeaders());
    }

     // Get all customer quotes by customer ID
     getCustomerQuoteByCustId(customerId) {
        return apiClient.get(`/customer_quotes_by_custid/${customerId}`, this.getAuthHeaders());
    }
}
