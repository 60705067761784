import axios from 'axios';

// Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5002';
const REMOTE_API_BASE_URL = 'https://api.crediunisa2.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL
});

// Response interceptor
apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle token expiration (e.g., redirect to login, refresh token, etc.)
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export class ServiceOffer {
  // Helper function to get headers with Authorization token
  getAuthHeaders() {
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    };
  }

  getOffers() {
    return apiClient.get('/offers', this.getAuthHeaders());
  }

  addOffer(offer) {
    return apiClient.post('/offers', offer, this.getAuthHeaders());
  }

  updateOffer(offer, offer_id) {
    return apiClient.put(`/offers/${offer_id}`, offer, this.getAuthHeaders());
  }

  deleteOffer(offer_id) {
    return apiClient.delete(`/offers/${offer_id}`, this.getAuthHeaders());
  }

  getOfferById(offer_id) {
    return apiClient.get(`/offers/${offer_id}`, this.getAuthHeaders());
  }
}
