import React, { useState, useEffect, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ServiceService } from '../service/ServiceService';
import { v4 as uuidv4 } from 'uuid';

const Service = () => {
    let emptyService = {
        service_id: null,
        description: '',
        service_type_id: 0,
        image_data: null,
        price: 0,
        price_original: 0,
        fg_publish: 0,
        status: 0,
    };

    const [services, setServices] = useState([]);
    const [serviceDialog, setServiceDialog] = useState(false);
    const [deleteServiceDialog, setDeleteServiceDialog] = useState(false);
    const [deleteServicesDialog, setDeleteServicesDialog] = useState(false);
    const [service, setService] = useState(emptyService);
    const [selectedServices, setSelectedServices] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        const serviceService = new ServiceService();
        serviceService.getServices().then((response) => {
            setServices(response.data);
        });
    }, []);

    const openNew = () => {
        setService(emptyService);
        setSubmitted(false);
        setServiceDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setServiceDialog(false);
    };

    const hideDeleteServiceDialog = () => {
        setDeleteServiceDialog(false);
    };

    const hideDeleteServicesDialog = () => {
        setDeleteServicesDialog(false);
    };

    const saveService = () => {
        setSubmitted(true);
        if (validateRecord(service)) {
            const serviceService = new ServiceService();
            if (service.service_id == null) {
                serviceService.addService(service).then((response) => {
                    setServices([...services, response.data]);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Service Created',
                        life: 3000,
                    });
                    setServiceDialog(false);
                    setService(emptyService);
                }).catch((error) => {
                    console.error('Error saving new Service:', error);
                });
            } else {
                serviceService.updateService(service, service.service_id).then((response) => {
                    const updatedServices = services.map((s) => s.service_id === response.data.service_id ? service : s);
                    setServices(updatedServices);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Service Updated',
                        life: 3000,
                    });
                    setServiceDialog(false);
                    setService(emptyService);
                }).catch((error) => {
                    console.error('Error updating Service:', error);
                });
            }
        }
    };

    const validateRecord = (record) => {
        return record !== null && record !== undefined;
    };

    const editService = (service) => {
        setService({ ...service });
        setServiceDialog(true);
    };

    const confirmDeleteService = (service) => {
        setService(service);
        setDeleteServiceDialog(true);
    };

    const deleteService = () => {
        const serviceService = new ServiceService();
        serviceService.deleteService(service.service_id).then(() => {
            let updatedServices = services.filter((val) => val.service_id !== service.service_id);
            setServices(updatedServices);
            setDeleteServiceDialog(false);
            setService(emptyService);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Service Deleted', life: 3000 });
        }).catch((error) => {
            console.error('Error deleting Service:', error);
        });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < services.length; i++) {
            if (services[i].service_id === id) {
                index = i;
                break;
            }
        }
        return index;
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                {rowData.image_data ? (
                    <img src={`data:image/png;base64,${rowData.image_data}`} alt="Service Image" className="shadow-2" width="100" />
                ) : (
                    'No Image'
                )}
            </>
        );
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteServicesDialog(true);
    };

    const deleteSelectedServices = () => {
        let _services = services.filter((val) => !selectedServices.includes(val));
        setServices(_services);
        setDeleteServicesDialog(false);
        setSelectedServices(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Services Deleted', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _service = { ...service };
        _service[`${name}`] = val;
        setService(_service);
    };
    const onInputNumberChange = (e, name) => {
        const val = e.value || 0; // Set default to 0 if value is undefined
        let _service = { ...service };
        _service[`${name}`] = val;
        setService(_service);
    
        // Debugging: Log to verify the values
        console.log("Updated Numeric Offer Field:", name, val);
    };
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedServices || !selectedServices.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.service_id}
            </>
        );
    };

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description}
            </>
        );
    };

    const serviceTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Service Type ID</span>
                {rowData.service_type_id}
            </>
        );
    };

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.price}
            </>
        );
    };

    const priceOriginalBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Original Price</span>
                {rowData.price_original}
            </>
        );
    };

    const fgPublishBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Publish</span>
                {rowData.fg_publish}
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {rowData.status}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editService(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteService(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Services</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const serviceDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveService} />
        </>
    );

    const deleteServiceDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteServiceDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteService} />
        </>
    );

    const deleteServicesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteServicesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedServices} />
        </>
    );

    const onImageSelect = (event) => {
        const file = event.files[0]; // Assuming you allow only one image to be uploaded
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageDataUrl = e.target.result;
                const base64Image = imageDataUrl.split(',')[1]; // Extract the Base64 part
                setService({ ...service, image_data: base64Image });
                setPreviewImage(imageDataUrl);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={services}
                        selection={selectedServices}
                        onSelectionChange={(e) => setSelectedServices(e.value)}
                        dataKey="service_id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} services"
                        globalFilter={globalFilter}
                        emptyMessage="No services found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="service_id" header="ID" body={idBodyTemplate}></Column>
                        <Column field="description" header="Description" sortable body={descriptionBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="service_type_id" header="Service Type ID" sortable body={serviceTypeBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column header="Image" body={imageBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="price" header="Price" sortable body={priceBodyTemplate} headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                        <Column field="price_original" header="Original Price" sortable body={priceOriginalBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="fg_publish" header="Publish" body={fgPublishBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="status" header="Status" body={statusBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={serviceDialog} style={{ width: '450px' }} header="Service Details" modal className="p-fluid" footer={serviceDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputText id="description" value={service.description} onChange={(e) => onInputChange(e, 'description')} required autoFocus className={classNames({ 'p-invalid': submitted && !service.description })} />
                            {submitted && !service.description && <small className="p-invalid">Description is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="service_type_id">Service Type ID</label>
                            <InputNumber
                                id="service_type_id"
                                value={service.service_type_id}
                                onChange={(e) => onInputNumberChange(e, 'service_type_id')}
                                required
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="price">Price</label>
                            <InputNumber id="price" value={service.price} onChange={(e) => onInputNumberChange(e, 'price')} required mode="currency" currency="USD" locale="en-US" />
                        </div>
                        <div className="field">
                            <label htmlFor="price_original">Original Price</label>
                            <InputNumber id="price_original" value={service.price_original} onChange={(e) => onInputNumberChange(e, 'price_original')} required mode="currency" currency="USD" locale="en-US" />
                        </div>
                        <div className="field">
                            <label htmlFor="fg_publish">Publish</label>
                            <Dropdown id="fg_publish" value={service.fg_publish} options={[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]} onChange={(e) => onInputChange(e, 'fg_publish')} />
                        </div>
                        <div className="field">
                            <label htmlFor="status">Status</label>
                            <Dropdown id="status" value={service.status} options={[{ label: 'Active', value: 0 }, { label: 'Inactive', value: 1 }]} onChange={(e) => onInputChange(e, 'status')} />
                        </div>
                        <div className="field">
                            <label htmlFor="image">Image</label>
                            <FileUpload
                                name="demo"
                                chooseLabel="Upload"
                                mode="basic"
                                auto
                                accept="image/png"
                                onSelect={(e) => onImageSelect(e)}
                            />
                            {previewImage && (
                                <div>
                                    <label>Selected Image Preview:</label>
                                    <img src={previewImage} alt="Selected Image" width="150" />
                                </div>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteServiceDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteServiceDialogFooter} onHide={hideDeleteServiceDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {service && (
                                <span>
                                    Are you sure you want to delete <b>{service.description}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteServicesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteServicesDialogFooter} onHide={hideDeleteServicesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {service && <span>Are you sure you want to delete the selected services?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Service;
