import React, { useState, useEffect, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ServiceOffer } from '../service/ServiceOffer';
import { v4 as uuidv4 } from 'uuid';

const Offer = () => {
    let emptyOffer = {
        offer_id: null,
        description: '',
        name: '',
        image_data: null,
        price: 0,
        price_original: 0,
        fg_publish: 0,
        status: 0,
    };

    const [offers, setOffers] = useState([]);
    const [offerDialog, setOfferDialog] = useState(false);
    const [deleteOfferDialog, setDeleteOfferDialog] = useState(false);
    const [deleteOffersDialog, setDeleteOffersDialog] = useState(false);
    const [offer, setOffer] = useState(emptyOffer);
    const [selectedOffers, setSelectedOffers] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        const serviceOffer = new ServiceOffer();
        serviceOffer.getOffers().then((response) => {
            setOffers(response.data);
        });
    }, []);

    const openNew = () => {
        setOffer(emptyOffer);
        setSubmitted(false);
        setOfferDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setOfferDialog(false);
    };

    const hideDeleteOfferDialog = () => {
        setDeleteOfferDialog(false);
    };

    const hideDeleteOffersDialog = () => {
        setDeleteOffersDialog(false);
    };

    const saveOffer = () => {
        setSubmitted(true);
        if (validateRecord(offer)) {
            const serviceOffer = new ServiceOffer();
            if (offer.offer_id == null) {
                serviceOffer.addOffer(offer).then((response) => {
                    setOffers([...offers, response.data]);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Offer Created',
                        life: 3000,
                    });
                    setOfferDialog(false);
                    setOffer(emptyOffer);
                }).catch((error) => {
                    console.error('Error saving new Offer:', error);
                });
            } else {
                serviceOffer.updateOffer(offer, offer.offer_id).then((response) => {
                    const updatedOffers = offers.map((s) => s.offer_id === response.data.offer_id ? offer : s);
                    setOffers(updatedOffers);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Offer Updated',
                        life: 3000,
                    });
                    setOfferDialog(false);
                    setOffer(emptyOffer);
                }).catch((error) => {
                    console.error('Error updating Offer:', error);
                });
            }
        }
    };

    const validateRecord = (record) => {
        return record !== null && record !== undefined;
    };

    const editOffer = (offer) => {
        setOffer({ ...offer });
        setOfferDialog(true);
    };

    const confirmDeleteOffer = (offer) => {
        setOffer(offer);
        setDeleteOfferDialog(true);
    };

    const deleteOffer = () => {
        const serviceOffer = new ServiceOffer();
        serviceOffer.deleteOffer(offer.offer_id).then(() => {
            let updatedOffers = offers.filter((val) => val.offer_id !== offer.offer_id);
            setOffers(updatedOffers);
            setDeleteOfferDialog(false);
            setOffer(emptyOffer);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Offer Deleted', life: 3000 });
        }).catch((error) => {
            console.error('Error deleting Offer:', error);
        });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < offers.length; i++) {
            if (offers[i].offer_id === id) {
                index = i;
                break;
            }
        }
        return index;
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                {rowData.image_data ? (
                    <img src={`data:image/png;base64,${rowData.image_data}`} alt="Offer Image" className="shadow-2" width="100" />
                ) : (
                    'No Image'
                )}
            </>
        );
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteOffersDialog(true);
    };

    const deleteSelectedOffers = () => {
        let _offers = offers.filter((val) => !selectedOffers.includes(val));
        setOffers(_offers);
        setDeleteOffersDialog(false);
        setSelectedOffers(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Offers Deleted', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _offer = { ...offer };
        _offer[`${name}`] = val;
        setOffer(_offer);
    };
    const onInputNumberChange = (e, name) => {
        const val = e.value || 0; // Set default to 0 if value is undefined
        let _offer = { ...offer };
        _offer[`${name}`] = val;
        setOffer(_offer);
    
        // Debugging: Log to verify the values
        console.log("Updated Numeric Offer Field:", name, val);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedOffers || !selectedOffers.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.offer_id}
            </>
        );
    };

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    };

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.price}
            </>
        );
    };

    const priceOriginalBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Original Price</span>
                {rowData.price_original}
            </>
        );
    };

    const fgPublishBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Publish</span>
                {rowData.fg_publish}
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {rowData.status}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editOffer(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteOffer(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Offers</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const offerDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveOffer} />
        </>
    );

    const deleteOfferDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteOfferDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteOffer} />
        </>
    );

    const deleteOffersDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteOffersDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedOffers} />
        </>
    );

    const onImageSelect = (event) => {
        const file = event.files[0]; // Assuming you allow only one image to be uploaded
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageDataUrl = e.target.result;
                const base64Image = imageDataUrl.split(',')[1]; // Extract the Base64 part
                setOffer({ ...offer, image_data: base64Image });
                setPreviewImage(imageDataUrl);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={offers}
                        selection={selectedOffers}
                        onSelectionChange={(e) => setSelectedOffers(e.value)}
                        dataKey="offer_id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} offers"
                        globalFilter={globalFilter}
                        emptyMessage="No offers found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="offer_id" header="ID" body={idBodyTemplate}></Column>
                        <Column field="name" header="Name" sortable body={nameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="description" header="Description" sortable body={descriptionBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column header="Image" body={imageBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="price" header="Price" sortable body={priceBodyTemplate} headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                        <Column field="price_original" header="Original Price" sortable body={priceOriginalBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="fg_publish" header="Publish" body={fgPublishBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="status" header="Status" body={statusBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog
                        visible={offerDialog}
                        style={{ width: '450px' }}
                        header="Offer Details"
                        modal
                        className="p-fluid"
                        footer={offerDialogFooter}
                        onHide={hideDialog}
                        >
                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText
                            id="name"
                            value={offer.name}
                            onChange={(e) => onInputChange(e, 'name')}
                            required
                            autoFocus // Set autoFocus here
                            className={classNames({ 'p-invalid': submitted && !offer.name })}
                            />
                            {submitted && !offer.name && (
                            <small className="p-invalid">Name is required.</small>
                            )}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea
                            id="description"
                            value={offer.description}
                            onChange={(e) => onInputChange(e, 'description')}
                            required
                            rows={5}
                            cols={30}
                            className={classNames({ 'p-invalid': submitted && !offer.description })}
                            />
                            {submitted && !offer.description && (
                            <small className="p-invalid">Description is required.</small>
                            )}
                        </div>
                        {/* Price Field */}
                        <div className="field">
                            <label htmlFor="price">Price</label>
                            <InputNumber
                                id="price"
                                value={offer.price}
                                onChange={(e) => onInputNumberChange(e, 'price')}
                                required
                                mode="currency"
                                currency="USD"
                                locale="en-US"
                            />
                        </div>

                        {/* Original Price Field */}
                        <div className="field">
                            <label htmlFor="price_original">Original Price</label>
                            <InputNumber
                                id="price_original"
                                value={offer.price_original}
                                onChange={(e) => onInputNumberChange(e, 'price_original')}
                                required
                                mode="currency"
                                currency="USD"
                                locale="en-US"
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="fg_publish">Publish</label>
                            <Dropdown
                            id="fg_publish"
                            value={offer.fg_publish}
                            options={[
                                { label: 'Yes', value: 1 },
                                { label: 'No', value: 0 },
                            ]}
                            onChange={(e) => onInputChange(e, 'fg_publish')}
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="status">Status</label>
                            <Dropdown
                            id="status"
                            value={offer.status}
                            options={[
                                { label: 'Active', value: 0 },
                                { label: 'Inactive', value: 1 },
                            ]}
                            onChange={(e) => onInputChange(e, 'status')}
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="image">Image</label>
                            <FileUpload
                            name="demo"
                            chooseLabel="Upload"
                            mode="basic"
                            auto
                            accept="image/png"
                            onSelect={(e) => onImageSelect(e)}
                            />
                            {previewImage && (
                            <div>
                                <label>Selected Image Preview:</label>
                                <img src={previewImage} alt="Selected Image" width="150" />
                            </div>
                            )}
                        </div>
                    </Dialog>


                    <Dialog visible={deleteOfferDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteOfferDialogFooter} onHide={hideDeleteOfferDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {offer && (
                                <span>
                                    Are you sure you want to delete <b>{offer.description}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteOffersDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteOffersDialogFooter} onHide={hideDeleteOffersDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {offer && <span>Are you sure you want to delete the selected offers?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Offer;
