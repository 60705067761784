import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CustomerService } from '../service/CustomerService';
import { CustomerQuoteService } from '../service/CustomerQuoteService'; // Import the new service
import { CreditService } from '../service/CreditService';
import { CreditSettingService } from '../service/CreditSettingService';
import { TransactionService } from '../service/TransactionService';
import { CountryService } from '../service/CountryService';
import { StateService } from '../service/StateService';
import { CustomerFamilyService } from '../service/CustomerFamilyService'; // Import the new service
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Avatar } from 'primereact/avatar';

const ClientDetail = () => {
    const { customerId } = useParams();
    const [customer, setCustomer] = useState(null);
    const [credits, setCredits] = useState([]);
    const [customerQuotes, setCustomerQuotes] = useState([]); // State to store customer quotes
    const [transactions, setTransactions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [customerFamilies, setCustomerFamilies] = useState([]);
    const [customerDialog, setCustomerDialog] = useState(false);
    const [familyDialog, setFamilyDialog] = useState(false); // Add state for the family dialog
    const [submitted, setSubmitted] = useState(false);
    const [previewImageId, setPreviewImageId] = useState(null);
    const [previewImagePhoto, setPreviewImagePhoto] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [creditAmount, setCreditAmount] = useState(0);
    const [availableAmount, setAvailableAmount] = useState(0);
    const [balanceAmount, setBalanceAmount] = useState(0);
    const [creditStatus, setCreditStatus] = useState('');
    const [rewardPoints, setRewardPoints] = useState(0);

    const [visible, setVisible] = useState(false);
    const [amount, setAmount] = useState('');
    const [typeId, setTypeId] = useState(null);
    const [description, setDescription] = useState('');
    const [balance, setBalance] = useState(0);

    const toast = useRef(null);

    const creditService = new CreditService();
    const creditSettingService = new CreditSettingService();
    const customerService = new CustomerService();
    const customerQuoteService = new CustomerQuoteService(); // Initialize CustomerQuoteService
    const transactionService = new TransactionService();

    const transactionTypes = [
        { label: 'Agregar Balance', value: 1 },
        { label: 'Substraer Balance', value: 2 }
    ];

    useEffect(() => {
        const countryService = new CountryService();
        const stateService = new StateService();
        const customerFamilyService = new CustomerFamilyService();

        customerService.getCustomerById(customerId).then((response) => {
            setCustomer(response.data);
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch customer details', life: 3000 });
        });

        customerQuoteService.getCustomerQuoteByCustId(customerId).then((response) => {
            setCustomerQuotes(response.data); // Store customer quotes
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch customer quotes', life: 3000 });
        });

        creditService.getCreditsByCustomerId(customerId).then((response) => {
            setCredits(response.data);
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch credits', life: 3000 });
        });

        transactionService.getTransactionsByCustomerId(customerId).then((response) => {
            setTransactions(response.data);
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch transactions', life: 3000 });
        });

        countryService.getAllCountries().then((response) => {
            setCountries(response.data);
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch countries', life: 3000 });
        });

        stateService.getAllStates().then((response) => {
            setStates(response.data);
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch states', life: 3000 });
        });

        customerService.getCreditBalance(customerId).then((response) => {
            const { credit_amount, available_amount, balance_amount, credit_status } = response.data;
            setCreditAmount(credit_amount);
            setAvailableAmount(available_amount);
            setBalanceAmount(balance_amount);
            setCreditStatus(credit_status);
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch credit balance', life: 3000 });
        });

        customerService.getRewardPoints(customerId).then((response) => {
            setRewardPoints(response.data.reward_points_balance);
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch reward points', life: 3000 });
        });
        

        customerFamilyService.getCustomerFamiliesByCustomerId(customerId).then((response) => {
            setCustomerFamilies(response.data);
        }).catch((error) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch customer families', life: 3000 });
        });
    }, [customerId]);

    
    const handleSaveCredit = () => {
        const currentDate = new Date();
        const currentDay = currentDate.getDate();
    
        creditSettingService.getCreditSettings().then((response) => {
            const settings = response.data;
    
            let selectedSetting = null;
            for (const setting of settings) {
                if (currentDay >= setting.start_day && currentDay <= setting.end_day) {
                    selectedSetting = setting;
                    break;
                }
            }
    
            if (selectedSetting) {
                creditService.getCreditsByCustomerId(customerId).then((response) => {
                    const credits = response.data;
    
                    const newCredit = {
                        customer_id: customerId,
                        due_date: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, selectedSetting.due_day),
                        closing_date: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, selectedSetting.closing_day),
                        amount: parseFloat(creditAmount),
                        status: 0,
                        updated_date: currentDate
                    };
    
                    if (credits.length === 0) {
                        newCredit.created_date = currentDate;
                        creditService.addCredit(newCredit).then(() => {
                            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Credit added successfully', life: 3000 });
                            setDisplayDialog(false);
                        }).catch(() => {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to add credit', life: 3000 });
                        });
                    } else {
                        const existingCredit = credits[0];
                        newCredit.status=creditStatus;
                        creditService.updateCredit(newCredit, existingCredit.rec_id).then(() => {
                            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Credit updated successfully', life: 3000 });
                            setDisplayDialog(false);
                        }).catch(() => {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update credit', life: 3000 });
                        });
                    }
                }).catch(() => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch credits', life: 3000 });
                });
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'No matching credit setting found', life: 3000 });
            }
        }).catch(() => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch credit settings', life: 3000 });
        });
    };
    
    // Add this function to handle dialog visibility
    const handleHideDialog = () => {
        setDisplayDialog(false);
    };
    
    // Add this function to handle input change
    const onCreditAmountChange = (e) => {
        setCreditAmount(e.target.value);
    };

    const formatCreditAmount = () => {
        const amount = parseFloat(creditAmount);
        if (isNaN(amount)) return '0.00'; // Default value if conversion fails
        return amount.toFixed(2);
    };

    const formatDueDate = (dateString) => {
        const date = new Date(dateString);
        return !isNaN(date)
            ? date.toLocaleDateString('es-ES', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
              })
            : '';
    };

    const editCustomer = () => {
        setSubmitted(false);
        setCustomerDialog(true);
        setPreviewImageId(customer.image_id ? `data:image/png;base64,${customer.image_id}` : null);
        setPreviewImagePhoto(customer.image_photo ? `data:image/png;base64,${customer.image_photo}` : null);
    };

    const showFamilyDialog = () => {
        setFamilyDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setCustomerDialog(false);
        setFamilyDialog(false); // Add hide for the family dialog
        setDisplayDialog(false);
    };


    const showWalletDialog = () => {
        setVisible(true);
    };

    const hideWalletDialog = () => {
        setVisible(false);
        setAmount('');
        setTypeId(null);
        setDescription('');
    };

    const saveWalletTransaction = async () => {
        if (!amount || !typeId || !description) {
          toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please fill all fields', life: 3000 });
          return;
        }
    
        const created_date = new Date().toISOString();
        const transaction = {
          customer_id: customerId,
          type_id: typeId,
          description: description,
          created_date: created_date,
          amount: parseFloat(amount),
          balance: calculateBalance(parseFloat(amount), typeId)
        };
    
        try {
          await transactionService.addTransaction(transaction);
          updateBalanceAndTransactions();
          hideWalletDialog();
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Transaction saved successfully', life: 3000 });
        } catch (error) {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to save transaction', life: 3000 });
        }
      };
    
      const calculateBalance = (amount, typeId) => {
        let newBalance = balanceAmount;
        if (typeId === 1) {
          newBalance += amount;
        } else if (typeId === 2) {
          newBalance -= amount;
        }
        return newBalance;
      };
    
      const updateBalanceAndTransactions = async () => {
        try {
          const balanceData = await customerService.getCreditBalance(customerId);
          setBalance(parseFloat(balanceData));

            transactionService.getTransactionsByCustomerId(customerId).then((response) => {
            setTransactions(response.data);
            }).catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch transactions', life: 3000 });
            });
          
        //   const transactionsData = await transactionService.getTransactionsByCustomerId(customerId);
        //   setTransactions(Array.isArray(transactionsData) ? transactionsData : []);
        } catch (error) {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch data', life: 3000 });
        }
      };
    
      useEffect(() => {
        updateBalanceAndTransactions();
      }, [customerId]);

    const saveCustomer = () => {
        setSubmitted(true);

        if (validateRecord(customer)) {
            // Extracting ids before sending to backend
            const updatedCustomer = {
                ...customer,
                state_id: customer.state_id.id || customer.state_id,  // Ensuring id is sent
                country_id: customer.country_id.id || customer.country_id  // Ensuring id is sent
            };
            
            const customerService = new CustomerService();
            customerService.updateCustomer(customer.customer_id, customer).then(() => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Customer Updated', life: 3000 });
                setCustomerDialog(false);
            }).catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update customer', life: 3000 });
            });
        }
    };

    const validateRecord = (record) => {
        return record !== null && record !== undefined;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _customer = { ...customer };
        if (name === 'state_id' || name === 'country_id') {
            _customer[`${name}`] = e.value.id;  // Extract the id
        } else {
            _customer[`${name}`] = val;
        }
        setCustomer(_customer);
    };
    const onImageSelect = (event, imageType) => {
        const file = event.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageDataUrl = e.target.result;
                const base64Image = imageDataUrl.split(',')[1];
                let _customer = { ...customer };
                if (imageType === 'image_id') {
                    _customer.image_id = base64Image;
                    setPreviewImageId(imageDataUrl);
                } else {
                    _customer.image_photo = base64Image;
                    setPreviewImagePhoto(imageDataUrl);
                }
                setCustomer(_customer);
            };
            reader.readAsDataURL(file);
        }
    };
    const getStatusDescription = (status) => {
        switch (status) {
            case 0:
                return 'No Asignado';
            case 1:
                return 'Recaudos en Revision';
            case 2:
                return 'Activo';
            case 3:
                return 'Suspendido';
            default:
                return 'Desconocido';
        }
    };

    const customerDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveCustomer} />
        </>
    );

    return (
        <div className="grid">
            <Toast ref={toast} />

            <div className="col-12 md:col-5">
                <div className="card">
                    <div className="grid p-nogutter">
                        <div className="col-10">
                            <h5>Datos del Afiliado</h5>
                            {customer && (
                                <div>
                                    <p><i className="pi pi-user" style={{ marginRight: '5px' }}></i><strong>Nombre:</strong> {customer.first_name} {customer.last_name}</p>
                                    <p><i className="pi pi-envelope" style={{ marginRight: '5px' }}></i><strong>Email:</strong> {customer.email}</p>
                                    <p><i className="pi pi-phone" style={{ marginRight: '5px' }}></i><strong>Teléfono:</strong> {customer.phone}</p>
                                    <p><i className="pi pi-calendar" style={{ marginRight: '5px' }}></i><strong>Fecha de Nacimiento:</strong> {customer.birth_date}</p>
                                    <p><i className="pi pi-home" style={{ marginRight: '5px' }}></i><strong>Dirección:</strong> {customer.address1}, {customer.address2}</p>
                                    <p><i className="pi pi-map-marker" style={{ marginRight: '5px' }}></i><strong>Ciudad:</strong> {customer.city} - {customer.zipcode}</p>
                                    <p><i className="pi pi-globe" style={{ marginRight: '5px' }}></i><strong>Estado:</strong> {customer.state_name} - {customer.country_name}</p>
                                    <p><i className="pi pi-clock" style={{ marginRight: '5px' }}></i><strong>Fecha de Creación:</strong> {customer.created_at}</p>
                                    <Button label="Editar Perfil" icon="pi pi-pencil" className="p-button-success" onClick={editCustomer} style={{ marginRight: '10px' }} />
                                    <Button label="Reiniciar PIN" icon="pi pi-refresh" className="p-button-warning" style={{ marginRight: '10px' }} />
                                    <Button label="Familiares" icon="pi pi-users" className="p-button-info" onClick={showFamilyDialog} />
                                </div>
                            )}
                        </div>
                        <div className="col-2" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }}>
                            {customer && customer.image_photo ? (
                                <img src={`data:image/png;base64,${customer.image_photo}`} alt="Customer" width="50" height="50" style={{ borderRadius: '50%' }} />
                            ) : (
                                <Avatar icon="pi pi-user" shape="circle" size="large" />
                            )}
                            <div style={{ textAlign: 'center' , marginTop: '8px' }}>
                                <p>
                                    <i className="pi pi-star" style={{ marginRight: '.5em' }}></i>
                                    <strong>Puntos</strong> 
                                </p>
                                <p style={{ fontSize: '1.5em' }}>{rewardPoints.toFixed(0)}</p>
                                
                               
                               {/* Corrected check for customer.image_id */}
                               {customer && customer.image_id && (
                                <img
                                    src={`data:image/png;base64,${customer.image_id}`}
                                    alt="Customer ID"
                                    width="100"
                                    style={{ height: 'auto', marginRight: '8px', marginTop: '8px' }}
                                />
                                )}
                            </div>
                        </div>

                    </div>
                </div>


                <div className="card" style={{ marginTop: '2em' }}>
                    <h5>Estado de Créditos</h5>
                    {customer && (
                        <div style={{ marginBottom: '1em' }}>
                            <div className="grid">
                                <div className="col-4">
                                    <strong>DISPONIBLE:</strong> {availableAmount.toFixed(2)} $
                                </div>
                                <div className="col-4">
                                    <strong>CREDITO:</strong> {formatCreditAmount()} $
                                </div>
                                <div className="col-4">
                                    <strong>ESTADO:</strong>  {getStatusDescription(creditStatus)}
                                </div>
                            </div>
                            <ProgressBar value={(availableAmount / creditAmount) * 100} showValue={false} />
                            <div className="grid">
                                <div className="col-12" style={{ textAlign: 'center' }}>
                                    <strong>SALDO ACTUAL:</strong> {balanceAmount.toFixed(2)} $
                                </div>
                            </div>
                            
                            <div className="grid">
                                <div className="col-4">
                                    <Button label="Conf. Credito" icon="pi pi-check" className="p-button-success"  onClick={() => setDisplayDialog(true)}/>
                                </div>
                                <div className="col-4">
                                    <Button label="Recarga Wallet" icon="pi pi-wallet" className="p-button-info" onClick={showWalletDialog}/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>

            <div className="col-12 md:col-7">
                <div className="card">
                    <h5>Historial de Cuotas</h5>
                    <DataTable value={customerQuotes} paginator rows={3} responsiveLayout="scroll">
                        <Column field="description" header="Descripción" sortable />
                        <Column 
                            field="due_date" 
                            header="Fecha de Vencimiento" 
                            body={(rowData) => formatDueDate(rowData.due_date)} 
                            sortable 
                        />
                        <Column field="amount_original" header="Monto Quota" sortable />
                        <Column field="amount_received" header="Monto Recibido" sortable />
                        <Column field="reference" header="Referencia" sortable />
                        <Column field="status" header="Estado" sortable />
                    </DataTable>
                </div>
                <div className="card">
                    <h5>Historial de Transacciones</h5>
                    <DataTable value={transactions} paginator rows={10} responsiveLayout="scroll">
                        <Column field="created_date" header="Fecha" sortable />
                        <Column field="description" header="Descripción" sortable />
                        <Column field="amount" header="Monto" sortable />
                        <Column field="balance" header="Balance" sortable />
                    </DataTable>
                </div>
            </div>

            <Dialog visible={customerDialog} style={{ width: '450px' }} header="Editar Perfil" modal className="p-fluid" footer={customerDialogFooter} onHide={hideDialog}>
                {customer && (
                    <>
                        <div className="field">
                            <label htmlFor="first_name">Nombre</label>
                            <InputText id="first_name" value={customer.first_name} onChange={(e) => onInputChange(e, 'first_name')} required autoFocus className={classNames({ 'p-invalid': submitted && !customer.first_name })} />
                            {submitted && !customer.first_name && <small className="p-invalid">Nombre es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="last_name">Apellido</label>
                            <InputText id="last_name" value={customer.last_name} onChange={(e) => onInputChange(e, 'last_name')} required className={classNames({ 'p-invalid': submitted && !customer.last_name })} />
                            {submitted && !customer.last_name && <small className="p-invalid">Apellido es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="email">Email</label>
                            <InputText id="email" value={customer.email} onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': submitted && !customer.email })} />
                            {submitted && !customer.email && <small className="p-invalid">Email es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="phone">Teléfono</label>
                            <InputText id="phone" value={customer.phone} onChange={(e) => onInputChange(e, 'phone')} required className={classNames({ 'p-invalid': submitted && !customer.phone })} />
                            {submitted && !customer.phone && <small className="p-invalid">Teléfono es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="birth_date">Fecha de Nacimiento</label>
                            <Calendar id="birth_date" value={new Date(customer.birth_date)} onChange={(e) => onInputChange(e, 'birth_date')} dateFormat="yy-mm-dd" showIcon />
                        </div>
                        <div className="field">
                            <label htmlFor="address1">Dirección 1</label>
                            <InputTextarea id="address1" value={customer.address1} onChange={(e) => onInputChange(e, 'address1')} required rows={3} cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="address2">Dirección 2</label>
                            <InputTextarea id="address2" value={customer.address2} onChange={(e) => onInputChange(e, 'address2')} rows={3} cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="city">Ciudad</label>
                            <InputText id="city" value={customer.city} onChange={(e) => onInputChange(e, 'city')} required />
                        </div>
                        <div className="field">
                            <label htmlFor="state_name">Estado</label>
                            <Dropdown id="state_name" value={states.find(state => state.id === customer.state_id)} options={states} onChange={(e) => onInputChange(e, 'state_id')} optionLabel="name" placeholder="Seleccione un estado" />
                        </div>
                        <div className="field">
                            <label htmlFor="country_name">País</label>
                            <Dropdown id="country_name" value={countries.find(country => country.id === customer.country_id)} options={countries} onChange={(e) => onInputChange(e, 'country_id')} optionLabel="name" placeholder="Seleccione un país" />
                        </div>
                        <div className="field">
                            <label htmlFor="zipcode">Código Postal</label>
                            <InputText id="zipcode" value={customer.zipcode} onChange={(e) => onInputChange(e, 'zipcode')} required />
                        </div>
                        <div className="field">
                            <label htmlFor="image_id">Imagen de ID</label>
                            <FileUpload name="image_id" chooseLabel="Subir ID" mode="basic" auto accept="image/*" onSelect={(e) => onImageSelect(e, 'image_id')} />
                            {previewImageId && <img src={previewImageId} alt="ID" width="150" />}
                        </div>
                        <div className="field">
                            <label htmlFor="image_photo">Foto</label>
                            <FileUpload name="image_photo" chooseLabel="Subir Foto" mode="basic" auto accept="image/*" onSelect={(e) => onImageSelect(e, 'image_photo')} />
                            {previewImagePhoto && <img src={previewImagePhoto} alt="Foto" width="150" />}
                        </div>
                    </>
                )}
            </Dialog>

            
            <Dialog visible={familyDialog} style={{ width: '450px' }} header="Familiares" modal className="p-fluid" onHide={hideDialog}>
                <DataTable value={customerFamilies} paginator rows={10} responsiveLayout="scroll">
                    <Column field="doc_id" header="Documento de Identidad" sortable />
                    <Column field="names" header="Nombres" sortable />
                </DataTable>
            </Dialog>

            // Add this to the return statement to include the dialog
            <Dialog header="Enter Credit Amount" visible={displayDialog} style={{ width: '50vw' }} footer={
                <div>
                    <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={handleHideDialog} />
                    <Button label="Guardar" icon="pi pi-check" className="p-button-success" onClick={handleSaveCredit} />
                </div>
            } onHide={handleHideDialog}>
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="creditAmount">Credit Amount</label>
                        <InputText id="creditAmount" value={creditAmount} onChange={onCreditAmountChange} />
                    </div>
                    <div className="field">
                        <label htmlFor="credit_status">Estado del Crédito</label>
                        <Dropdown id="credit_status" value={creditStatus} options={[
                            { label: 'No Asignado', value: 0 },
                            { label: 'Recaudos en Revisión', value: 1 },
                            { label: 'Activo', value: 2 },
                            { label: 'Suspendido', value: 3 }
                        ]} onChange={(e) => setCreditStatus(e.value)} placeholder="Seleccione un estado" />
                    </div>
                </div>
            </Dialog>

            <Dialog header="Recarga Wallet" visible={visible} onHide={hideWalletDialog} footer={(
                <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={hideWalletDialog} className="p-button-text" />
                <Button label="Guardar" icon="pi pi-check" onClick={saveWalletTransaction} className="p-button-text" />
                </div>
            )}>
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="amount">Amount</label>
                        <InputText id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="type">Type</label>
                        <Dropdown id="type" value={typeId} options={transactionTypes} onChange={(e) => setTypeId(e.value)} placeholder="Select a Type" />
                    </div>
                    <div className="p-field">
                        <label htmlFor="description">Description</label>
                        <InputText id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                    </div>
                </div>
            </Dialog>

        </div>
    );
};

export default ClientDetail;
