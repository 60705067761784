import React, { useEffect, useRef, useState } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation, Navigate, useNavigate } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppBreadcrumb from './AppBreadcrumb';
import AppInlineMenu from './AppInlineMenu';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppConfig from './AppConfig';
import AppRightMenu from './AppRightMenu';

import Dashboard from './pages/Dashboard';
import DashboardAnalytics from './components/DashboardAnalytics';
import ButtonDemo from './components/ButtonDemo';
import ChartDemo from './components/ChartDemo';
import MessagesDemo from './components/MessagesDemo';
import Documentation from './components/Documentation';
import FileDemo from './components/FileDemo';
import InputDemo from './components/InputDemo';
import ListDemo from './components/ListDemo';
import MiscDemo from './components/MiscDemo';
import MenuDemo from './components/MenuDemo';
import OverlayDemo from './components/OverlayDemo';
import PanelDemo from './components/PanelDemo';
import TableDemo from './components/TableDemo';
import TreeDemo from './components/TreeDemo';
import FloatLabelDemo from './components/FloatLabelDemo';
import InvalidStateDemo from './components/InvalidStateDemo';

import BlocksDemo from './components/BlocksDemo';
import IconsDemo from './utilities/IconsDemo';

import Crud from './pages/Crud';
import LoginPage from './pages/Login';
import ErrorPage from './pages/Error';
import LogoutPage from './pages/Logout';

import Client from './pages/Client';
import ClientDetail from './pages/ClientDetail';
import Service from './pages/Service';
import ServiceType from './pages/ServiceType';
import Offer from './pages/Offer';
import Transaction from './pages/Transaction';
import Credit from './pages/Credit';
import Account from './pages/Account';
import PaymentMethod from './pages/PaymentMethod';
import PaymentTransaction from './pages/PaymentTransaction';

import Calendar from './pages/Calendar';
import EmptyPage from './pages/EmptyPage';
import Invoice from './pages/Invoice';
import Help from './pages/Help';
import TimelineDemo from './pages/TimelineDemo';

import TermsOfUse from './pages/TermsOfUse';
import SupportPage from './pages/SupportPage';
import CustomerOld from './pages/CustomerOld';
import PaymentTransaction2 from './pages/PaymentTransaction2';
import Report01 from './pages/Report01';


import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

export const RTLContext = React.createContext();

const App = () => {
    const [menuMode, setMenuMode] = useState('static');
    const [inlineMenuPosition, setInlineMenuPosition] = useState('bottom');
    const [desktopMenuActive, setDesktopMenuActive] = useState(true);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [colorMode, setColorMode] = useState('light');
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('filled');
    const [isRTL, setRTL] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
    const [menuTheme, setMenuTheme] = useState('light');
    const [topbarTheme, setTopbarTheme] = useState('orange');
    const [theme, setTheme] = useState('indigo');
    const [isInputBackgroundChanged, setIsInputBackgroundChanged] = useState(false);
    const [inlineMenuActive, setInlineMenuActive] = useState({});
    const [newThemeLoaded, setNewThemeLoaded] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const copyTooltipRef = useRef();
    let currentInlineMenuKey = useRef(null);
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    PrimeReact.ripple = true;

    let searchClick;
    let topbarItemClick;
    let menuClick;
    let inlineMenuClick;

    const menu = [
        {
            label: 'Favorites',
            icon: 'pi pi-fw pi-home',
            items: [
                { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }
            ]
        },
        {
            label: 'Base De Datos',
            icon: 'pi pi-fw pi-star-fill',
            items: [
                { label: 'Afiliados', icon: 'pi pi-fw pi-id-card', to: '/pages/client' },
                { label: 'Afiliados Antiguos', icon: 'pi pi-fw pi-id-card', to: '/pages/customerold' },
                { label: 'Servicios', icon: 'pi pi-fw pi-briefcase', to: '/pages/service' },
                { label: 'Tipo de Servicios', icon: 'pi pi-fw pi-database', to: '/pages/servicetype' },
                { label: 'Servicios en Ofertas', icon: 'pi pi-fw pi-tag', to: '/pages/offer' },
                
            ]
        },
        {
            label: 'Pagos',
            icon: 'pi pi-fw pi-prime',
            items: [
                { label: 'Pendientes', icon: 'pi pi-fw pi-globe', to: '/pages/paymentTransaction'},
                { label: 'Aprobados', icon: 'pi pi-fw pi-eye', to: '/pages/paymentTransaction2'},
                { label: 'Domiciliación', icon: 'pi pi-fw pi-database', to: '/pages/report01' }
            ]
        },
        {
            label: 'Reportes',
            icon: 'pi pi-fw pi-star-fill',
            items: [
                { label: 'Transacciones', icon: 'pi pi-fw pi-chart-bar', to: '/pages/transaction' },
                { label: 'Saldos Afiliados', icon: 'pi pi-fw pi-chart-bar', to: '/pages/emptypage' },
                { label: 'Estados de Cuenta', icon: 'pi pi-fw pi-chart-bar', to: '/pages/emptypage' },
                { label: 'Domiciliación pagos', icon: 'pi pi-fw pi-chart-bar', to: '/pages/emptypage' },
            ]
        },
        
        {
            label: 'Admin',
            icon: 'pi pi-fw pi-cog',
            items: [
                { label: 'Creditos', icon: 'pi pi-fw pi-id-card', to: '/pages/credit' },
                { label: 'Payments Method', icon: 'pi pi-fw pi-dollar', to: '/pages/paymentMethod' },
                { label: 'Config Sistema', icon: 'pi pi-fw pi-dollar', to: '/pages/emptypage' },
                
            ]
        },
        {
            // label: 'Support',
            // icon: 'pi pi-fw pi-download',
            // items: [
            //     { label: 'Documentation', icon: 'pi pi-fw pi-question', to: '/documentation' },
            //     { label: 'Help', icon: 'pi pi-fw pi-question-circle', to: '/pages/help' },
                
            // ]
        }
    ];

    const routes = [
        { path: '/', parent: '', label: '' },
        { path: '/documentation', parent: 'Favorites', label: 'Dashboard Analytics' },
        { path: '/favorites/dashboardanalytics', parent: 'UI Kit', label: 'Form Layout' },
        { path: '/', parent: 'UI Kit', label: 'Float Label' },
        { path: '/', parent: 'UI Kit', label: 'Invalid State' },
        { path: '/', parent: 'UI Kit', label: 'Button' },
        { path: '/', parent: 'UI Kit', label: 'Table' },
        { path: '/', parent: 'UI Kit', label: 'List' },
        { path: '/', parent: 'UI Kit', label: 'Panel' },
        { path: '/', parent: 'UI Kit', label: 'Tree' },
        { path: '/', parent: 'UI Kit', label: 'Overlay' },
        { path: '/', parent: 'UI Kit', label: 'Menu' },
        { path: '/', parent: 'UI Kit', label: 'Message' },
        { path: '/', parent: 'UI Kit', label: 'File' },
        { path: '/', parent: 'UI Kit', label: 'Chart' },
        { path: '/', parent: 'UI Kit', label: 'Misc' },
        { path: '/', parent: 'Utilities', label: 'Icons' },
        { path: '/', parent: 'PrimeBlocks', label: 'Blocks' },
        { path: '/', parent: 'Pages', label: 'Crud' },

        { path: '/', parent: 'Pages', label: 'Client' },
        { path: '/', parent: 'Pages', label: 'ClientDetail' },
        { path: '/', parent: 'Pages', label: 'Service' },
        { path: '/', parent: 'Pages', label: 'ServiceType' },
        { path: '/', parent: 'Pages', label: 'Offer' },
        { path: '/', parent: 'Pages', label: 'Transaction' },
        { path: '/', parent: 'Pages', label: 'Credit' },
        { path: '/', parent: 'Pages', label: 'Account' },
        { path: '/', parent: 'Pages', label: 'PaymentMethod' },
        { path: '/', parent: 'Pages', label: 'PaymentTransaction' },
        { path: '/', parent: 'Pages', label: 'PaymentTransaction2' },
        { path: '/', parent: 'Pages', label: 'Report01' },
        { path: '/', parent: 'Pages', label: 'CustomerOld' },
        { path: '/', parent: 'Pages', label: 'TermsOfUse' },
        { path: '/', parent: 'Pages', label: 'SupportPage' },
        { path: '/', parent: 'Pages', label: 'Calendar' },
        { path: '/', parent: 'Pages', label: 'Timeline' },
        { path: '/', parent: 'Pages', label: 'Invoice' },
        { path: '/', parent: 'Pages', label: 'Login' },
        { path: '/', parent: 'Pages', label: 'Help' },
        { path: '/', parent: 'Pages', label: 'Empty' },
        { path: '/', parent: 'Pages', label: 'Access' },
        { path: '/', parent: 'Start', label: 'Documentation' }
    ];

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (menuMode === 'overlay') {
            hideOverlayMenu();
        }
        if (menuMode === 'static') {
            setDesktopMenuActive(true);
        }
    }, [menuMode]);

    useEffect(() => {
        onColorModeChange(colorMode);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onMenuThemeChange = (theme) => {
        setMenuTheme(theme);
    };

    const onTopbarThemeChange = (theme) => {
        setTopbarTheme(theme);
    };

    useEffect(() => {
        const appLogoLink = document.getElementById('app-logo');

        if (topbarTheme === 'white' || topbarTheme === 'yellow' || topbarTheme === 'amber' || topbarTheme === 'orange' || topbarTheme === 'lime') {
            appLogoLink.src = 'assets/layout/images/logo-crediunisa03.svg';
        } else {
            appLogoLink.src = 'assets/layout/images/logo-crediunisa03.svg';
        }
    }, [topbarTheme]);

    const onThemeChange = (theme) => {
        setTheme(theme);
        const themeLink = document.getElementById('theme-css');
        const themeHref = 'assets/theme/' + theme + '/theme-' + colorMode + '.css';
        replaceLink(themeLink, themeHref);
    };

    const onColorModeChange = (mode) => {
        setColorMode(mode);
        setIsInputBackgroundChanged(true);

        if (isInputBackgroundChanged) {
            if (mode === 'dark') {
                setInputStyle('filled');
            } else {
                setInputStyle('outlined');
            }
        }

        if (mode === 'dark') {
            setMenuTheme('dark');
            setTopbarTheme('dark');
        } else {
            setMenuTheme('light');
            setTopbarTheme('orange');
        }

        const layoutLink = document.getElementById('layout-css');
        const layoutHref = 'assets/layout/css/layout-' + mode + '.css';
        replaceLink(layoutLink, layoutHref);

        const themeLink = document.getElementById('theme-css');
        const urlTokens = themeLink.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = 'theme-' + mode + '.css';
        const newURL = urlTokens.join('/');

        replaceLink(themeLink, newURL, () => {
            setNewThemeLoaded(true);
        });
    };

    const replaceLink = (linkElement, href, callback) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);

            if (callback) {
                callback();
            }
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                const _linkElement = document.getElementById(id);
                _linkElement && _linkElement.remove();
                cloneLinkElement.setAttribute('id', id);

                if (callback) {
                    callback();
                }
            });
        }
    };

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onInlineMenuPositionChange = (mode) => {
        setInlineMenuPosition(mode);
    };

    const onMenuModeChange = (mode) => {
        setMenuMode(mode);
    };

    const onRTLChange = () => {
        setRTL((prevState) => !prevState);
    };

    const onMenuClick = (event) => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isDesktop()) setDesktopMenuActive((prevState) => !prevState);
        else setMobileMenuActive((prevState) => !prevState);

        event.preventDefault();
    };

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        if (activeTopbarItem === event.item) setActiveTopbarItem(null);
        else {
            setActiveTopbarItem(event.item);
        }

        event.originalEvent.preventDefault();
    };

    const onSearch = (event) => {
        searchClick = true;
        setSearchActive(event);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items && (menuMode === 'overlay' || !isDesktop())) {
            hideOverlayMenu();
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const onRootMenuItemClick = (event) => {
        setMenuActive((prevState) => !prevState);
    };

    const onRightMenuButtonClick = () => {
        setRightMenuActive((prevState) => !prevState);
    };

    const onMobileTopbarButtonClick = (event) => {
        setMobileTopbarActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onDocumentClick = (event) => {
        if (!searchClick && event.target.localName !== 'input') {
            setSearchActive(false);
        }

        if (!topbarItemClick) {
            setActiveTopbarItem(null);
        }

        if (!menuClick && (menuMode === 'overlay' || !isDesktop())) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }
            hideOverlayMenu();
        }

        if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
            let menuKeys = { ...inlineMenuActive };
            menuKeys[currentInlineMenuKey.current] = false;
            setInlineMenuActive(menuKeys);
        }

        if (!menuClick && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }

        searchClick = false;
        topbarItemClick = false;
        inlineMenuClick = false;
        menuClick = false;
    };

    const hideOverlayMenu = () => {
        setMobileMenuActive(false);
        setDesktopMenuActive(false);
    };

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const onInlineMenuClick = (e, key) => {
        let menuKeys = { ...inlineMenuActive };
        if (key !== currentInlineMenuKey.current && currentInlineMenuKey.current) {
            menuKeys[currentInlineMenuKey.current] = false;
        }

        menuKeys[key] = !menuKeys[key];
        setInlineMenuActive(menuKeys);
        currentInlineMenuKey.current = key;
        inlineMenuClick = true;
    };

    const layoutContainerClassName = classNames('layout-wrapper ', 'layout-menu-' + menuTheme + ' layout-topbar-' + topbarTheme, {
        'layout-menu-static': menuMode === 'static',
        'layout-menu-overlay': menuMode === 'overlay',
        'layout-menu-slim': menuMode === 'slim',
        'layout-menu-horizontal': menuMode === 'horizontal',
        'layout-menu-active': desktopMenuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple,
        'layout-rtl': isRTL
    });

    return (
        <RTLContext.Provider value={isRTL}>
            <div className={layoutContainerClassName} onClick={onDocumentClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar
                    horizontal={isHorizontal()}
                    activeTopbarItem={activeTopbarItem}
                    onMenuButtonClick={onMenuButtonClick}
                    onTopbarItemClick={onTopbarItemClick}
                    onRightMenuButtonClick={onRightMenuButtonClick}
                    onMobileTopbarButtonClick={onMobileTopbarButtonClick}
                    mobileTopbarActive={mobileTopbarActive}
                    searchActive={searchActive}
                    onSearch={onSearch}
                />

                <div className="menu-wrapper" onClick={onMenuClick}>
                    <div className="layout-menu-container">
                        {(inlineMenuPosition === 'top' || inlineMenuPosition === 'both') && <AppInlineMenu menuKey="top" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />}
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick} menuMode={menuMode} active={menuActive} />
                        {(inlineMenuPosition === 'bottom' || inlineMenuPosition === 'both') && (
                            <AppInlineMenu menuKey="bottom" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />
                        )}
                    </div>
                </div>

                <div className="layout-main">
                    <AppBreadcrumb routes={routes} />

                    <div className="layout-content">
                        <Routes>
                            <Route path="/login" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />
                            <Route path="/" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
                            <Route path="/error" element={<ErrorPage />} />
                            <Route path="/logout" element={<LogoutPage />} />
                            <Route path="/dashboard" element={<Dashboard colorMode={colorMode} isNewThemeLoaded={newThemeLoaded} onNewThemeChange={(e) => setNewThemeLoaded(e)} location={location} />} />
                            <Route path="/documentation" element={<Documentation />} />
                            <Route path="/favorites/dashboardanalytics" element={<DashboardAnalytics colorMode={colorMode} isNewThemeLoaded={newThemeLoaded} onNewThemeChange={(e) => setNewThemeLoaded(e)} location={location} />} />
                            <Route path="/uikit/floatlabel" element={<FloatLabelDemo />} />
                            <Route path="/uikit/input" element={<InputDemo />} />
                            <Route path="/uikit/invalidstate" element={<InvalidStateDemo />} />
                            <Route path="/uikit/button" element={<ButtonDemo />} />
                            <Route path="/uikit/table" element={<TableDemo />} />
                            <Route path="/uikit/list" element={<ListDemo />} />
                            <Route path="/uikit/tree" element={<TreeDemo />} />
                            <Route path="/uikit/panel" element={<PanelDemo />} />
                            <Route path="/uikit/overlay" element={<OverlayDemo />} />
                            <Route path="/uikit/menu/*" element={<MenuDemo />} />
                            <Route path="/uikit/message" element={<MessagesDemo />} />
                            <Route path="/uikit/file" element={<FileDemo />} />
                            <Route path="/uikit/chart" element={<ChartDemo colorMode={colorMode} isNewThemeLoaded={newThemeLoaded} onNewThemeChange={(e) => setNewThemeLoaded(e)} location={location} />} />
                            <Route path="/uikit/misc" element={<MiscDemo />} />
                            <Route path="/primeblocks/blocks" element={<BlocksDemo />} />
                            <Route path="/utilities/icons" element={<IconsDemo />} />
                            <Route path="/pages/crud" element={<Crud />} />

                            <Route path="/pages/client" element={<Client />} />
                            <Route path="/clientdetail/:customerId" element={<ClientDetail />} />
                            <Route path="/pages/service" element={<Service />} />
                            <Route path="/pages/servicetype" element={<ServiceType />} />
                            <Route path="/pages/offer" element={<Offer />} />
                            <Route path="/pages/transaction" element={<Transaction />} />
                            <Route path="/pages/credit" element={<Credit />} />
                            <Route path="/pages/account" element={<Account />} />
                            <Route path="/pages/paymentMethod" element={<PaymentMethod />} />
                            <Route path="/pages/paymentTransaction" element={<PaymentTransaction />} />
                            <Route path="/pages/paymentTransaction2" element={<PaymentTransaction2 />} />
                            <Route path="/pages/report01" element={<Report01 />} />
                            <Route path="/pages/customerOld" element={<CustomerOld />} />
                            <Route path="/pages/termsOfUse" element={<TermsOfUse />} />
                            <Route path="/pages/supportPage" element={<SupportPage />} />
                            <Route path="/pages/calendar" element={<Calendar />} />
                            <Route path="/pages/help" element={<Help />} />
                            <Route path="/pages/invoice" element={<Invoice />} />
                            <Route path="/pages/empty" element={<EmptyPage />} />
                            <Route path="/pages/timeline" element={<TimelineDemo />} />
                        </Routes>
                    </div>

                    <AppFooter colorMode={colorMode} />
                </div>

                <AppConfig
                    inputStyle={inputStyle}
                    onInputStyleChange={onInputStyleChange}
                    rippleEffect={ripple}
                    onRippleEffect={onRipple}
                    menuMode={menuMode}
                    onMenuModeChange={onMenuModeChange}
                    inlineMenuPosition={inlineMenuPosition}
                    onInlineMenuPositionChange={onInlineMenuPositionChange}
                    colorMode={colorMode}
                    onColorModeChange={onColorModeChange}
                    menuTheme={menuTheme}
                    onMenuThemeChange={onMenuThemeChange}
                    topbarTheme={topbarTheme}
                    onTopbarThemeChange={onTopbarThemeChange}
                    theme={theme}
                    onThemeChange={onThemeChange}
                    isRTL={isRTL}
                    onRTLChange={onRTLChange}
                />

                <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuButtonClick={onRightMenuButtonClick} />

                {mobileMenuActive && <div className="layout-mask modal-in"></div>}
            </div>
        </RTLContext.Provider>
    );
};

export default App;
