import React, { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { CustomerService } from '../service/ReportService'; // Adjust path if necessary
import { useNavigate } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';

const Report01 = () => {
    const [customers, setCustomers] = useState([]);
    const [reportDate, setReportDate] = useState(null);
    const toast = useRef(null);
    const navigate = useNavigate();
    const dt = useRef(null);

    const onGenerateReport = () => {
        if (!reportDate) {
            toast.current.show({ severity: 'warn', summary: 'Advertencia', detail: 'Debe seleccionar una fecha', life: 3000 });
            return;
        }

        const reportData = { report_date: reportDate.toISOString().split('T')[0] }; // Format as YYYY-MM-DD
        const customerService = new CustomerService();

        customerService.getCustomerReportDomiciliar(reportData).then((response) => {
            setCustomers(response.data);
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No se pudo generar el reporte', life: 3000 });
        });
    };

    const onDetail = (rowData) => {
        navigate(`/clientdetail/${rowData.customer_id}`);
    };

    const header = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Reporte de pagos por Domiciliación</h5>
            <div className="p-grid p-nogutter">
                <div className="p-col">
                    <Calendar value={reportDate} onChange={(e) => setReportDate(e.value)} dateFormat="yy-mm-dd" placeholder="Seleccionar fecha" />
                </div>
                <div className="p-col">
                    <Button label="Generar Reporte" icon="pi pi-search" onClick={onGenerateReport} />
                </div>
            </div>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <Button label="Detalles" icon="pi pi-info-circle" className="p-button-secondary" onClick={() => onDetail(rowData)} />
        );
    };

    const exportCSV = () => {
        const exportData = customers.map(customer => ({
            ...customer,
            bank_account: `\t"${customer.bank_account}"`, // Force Excel to treat as text
            "user.phone": `\t"${customer["user.phone"]}"`, // Force Excel to treat as text
        }));
        dt.current.exportCSV({ data: exportData });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Calendar value={reportDate} onChange={(e) => setReportDate(e.value)} dateFormat="yy-mm-dd" placeholder="Seleccionar fecha" style={{ marginRight: '10px'}}/>
                    <Button label="Generar Reporte" icon="pi pi-search" onClick={onGenerateReport} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                
                <Button label="Export" icon="pi pi-upload" className="p-button-help" 
                    onClick={exportCSV} />
            </React.Fragment>
        );
    };

    return (
        <div className="datatable-crud-demo">
            <Toast ref={toast} />
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

            <div className="card">
                <DataTable ref={dt} value={customers} paginator rows={10} dataKey="customer_id" responsiveLayout="scroll">
                    <Column field="customer_id" header="ID" sortable />
                    <Column field="doc_id" header="Documento" sortable />
                    <Column field="user.first_name" header="Nombre" sortable />
                    <Column field="user.last_name" header="Apellido" sortable />
                    <Column field="user.email" header="Email" sortable />
                    <Column field="user.phone" header="Teléfono" sortable />
                    <Column field="bank_id" header="Banco" sortable />
                    <Column field="bank_account" header="Cuenta Bancaria" sortable />
                    <Column field="due_date" header="Fecha de Vencimiento" sortable />
                    <Column field="minimumPayment" header="Pago Mínimo" sortable />
                    <Column body={actionBodyTemplate} header="Acciones" />
                </DataTable>
            </div>
        </div>
    );
};

export default Report01;
