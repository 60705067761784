import axios from 'axios';

// Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5002';
const REMOTE_API_BASE_URL = 'https://api.crediunisa2.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL
});

// Response interceptor
apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle token expiration (e.g., redirect to login, refresh token, etc.)
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export class ServiceTypeService {
  // Helper function to get headers with Authorization token
  getAuthHeaders() {
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    };
  }

  getServiceTypes() {
    return apiClient.get('/service_types', this.getAuthHeaders());
  }

  addServiceType(serviceType) {
    return apiClient.post('/service_types', serviceType, this.getAuthHeaders());
  }

  updateServiceType(serviceType, service_type_id) {
    return apiClient.put(`/service_types/${service_type_id}`, serviceType, this.getAuthHeaders());
  }

  deleteServiceType(service_type_id) {
    return apiClient.delete(`/service_types/${service_type_id}`, this.getAuthHeaders());
  }

  getServiceTypeById(service_type_id) {
    return apiClient.get(`/service_types/${service_type_id}`, this.getAuthHeaders());
  }
}
