import axios from 'axios';


// // Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5002';
const REMOTE_API_BASE_URL = 'https://api.crediunisa2.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL
});

// Response interceptor
apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle token expiration (e.g., redirect to login, refresh token, etc.)
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export class CustomerService {
    // Helper function to get headers with Authorization token
    getAuthHeaders() {
        const accessToken = localStorage.getItem('accessToken');
        return {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
        };
    }


    getCustomers() {
        return apiClient.get(`/customers`, this.getAuthHeaders());
    }

    getCustomers2() {
      return apiClient.get(`/customers2`, this.getAuthHeaders());
    }

    
    getCustomerById(customerId) {
      return apiClient.get(`/customers/${customerId}`, this.getAuthHeaders());
    }

    addCustomer(customerData) {
        return apiClient.post(`/customers`, customerData, this.getAuthHeaders());
    }

    updateCustomer(customerId, customerData) {
        return apiClient.put(`/customers/${customerId}`, customerData, this.getAuthHeaders());
    }

    deleteCustomer(customerId) {
        return apiClient.delete(`/customers/${customerId}`, this.getAuthHeaders());
    }


    getCreditBalance(customerId) {
        return apiClient.get(`/customer_balance`, {
            params: { customer_id: customerId },
            ...this.getAuthHeaders()
        });
    }

    getRewardPoints(customerId) {
        return apiClient.get(`/customer_reward_points`, {
            params: { customer_id: customerId },
            ...this.getAuthHeaders()
        });
    }

    getCustomerDoc(customerId) {
      return apiClient.get(`/customers_doc/${customerId}`, this.getAuthHeaders());
    }

    getCustomerReportDomiciliar(reportData) {
      return apiClient.post(`/report_domiciliacion`, reportData, this.getAuthHeaders());
  }
}
