import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';

// // Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://192.168.1.4:5002';
const REMOTE_API_BASE_URL = 'https://api.crediunisa2.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const SupportPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [incidentType, setIncidentType] = useState(null);
    const [description, setDescription] = useState('');
    const toast = useRef(null);

    const incidentTypes = [
        { label: 'Problema técnico', value: 'Technical Issue' },
        { label: 'Validacion de Pagos', value: 'Payment Issue' },
        { label: 'Balance de Cliente', value: 'Customer Credit Balance' },
        { label: 'Gestion de Cliente', value: 'Customer Management' },
        { label: 'Otro', value: 'Other' }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requestData = {
            name: name,
            email: email,
            incidentType: incidentType,
            description: description
        };
    
        try {
            const response = await fetch(API_BASE_URL+'/send_support_email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });
    
            if (response.ok) {
                toast.current.show({severity: 'success', summary: 'Éxito', detail: 'Tu reporte ha sido enviado.', life: 3000});
                // Reset form fields
                setName('');
                setEmail('');
                setIncidentType(null);
                setDescription('');
            } else {
                const errorData = await response.json();
                toast.current.show({severity: 'error', summary: 'Error', detail: errorData.error || 'Hubo un problema al enviar el reporte.', life: 3000});
            }
        } catch (error) {
            toast.current.show({severity: 'error', summary: 'Error', detail: 'Hubo un problema al enviar el reporte.', life: 3000});
        }
    };

    return (
        <div className="p-grid p-justify-center">
            <div className="p-col-12 p-md-8">
                <div className="card">
                    <h1>Support</h1>
                    <p>If you are experiencing any issues or have any inquiries, please fill out the form below to report the incident. Our support team will get back to you as soon as possible.</p>
                    
                    <form onSubmit={handleSubmit} className="p-fluid">
                        <div className="p-field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={name} onChange={(e) => setName(e.target.value)} required autoFocus />
                        </div>
                        
                        <div className="p-field">
                            <label htmlFor="email">Email</label>
                            <InputText id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>

                        <div className="p-field">
                            <label htmlFor="incidentType">Incident Type</label>
                            <Dropdown id="incidentType" value={incidentType} options={incidentTypes} onChange={(e) => setIncidentType(e.value)} placeholder="Select an Incident Type" required />
                        </div>

                        <div className="p-field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} rows={5} required />
                        </div>

                        <Button label="Submit" icon="pi pi-check" type="submit" className="p-mt-2" />
                    </form>
                </div>
            </div>
            <Toast ref={toast} />
        </div>
    );
};

export default SupportPage;
